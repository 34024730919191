<template>
  <div>
    <div class="container">
      <div class="dialog">
          <el-input 
            v-model="dialogContent"
            placeholder="请输入内容"
            type="textarea"
            :suffix-icon="Promotion"
            autosize
          >
          </el-input>
          <el-button class="dialog-button" type="primary" @click="handleButtonClick">点击获取内容</el-button>
        <div class="text-box" v-show="showTextBox" v-text="textBoxContent"></div>
      </div>
        <div class="map-container">
          <el-card class="map-card">
            <div id="map" :style="{ height: '600px', width: '100%' }"></div>
          </el-card>
        </div>
      </div>
  </div>
</template>
  
<script>
import { ElLoading } from 'element-plus'
import { Promotion } from '@element-plus/icons-vue'


export default {
  data() {
    const jsonData = require('@/assets/data.json');
    return {
      points: [
        { lng: 120.63573, lat: 31.330181, name: "拙政园" },
      ],
      paths: [], // 添加 paths 数组
      dialogContent: '',
      sceneData: jsonData,
      showTextBox: false,
      textBoxContent: "这是一个文本框",
    };
  },
  mounted() {
    this.initMap();
    this.drawMap();
  },
  methods: {
    initMap() {
      const BMap = window.BMap;
      const map = new BMap.Map("map");
      this.mymap = map;
    },
    drawMap() {
      const BMap = window.BMap;
      this.mymap.clearOverlays();

      console.log("步行点长度：", this.points.length);

      this.mymap.centerAndZoom(new BMap.Point(this.points[0].lng, this.points[0].lat), 15);

      this.points.forEach((point, index) => {
        const bmapPoint = new BMap.Point(point.lng, point.lat);
        this.addMarkerWithLabel(this.mymap, bmapPoint, point.name);
      });

      if (this.points.length <= 1) {
        return;
      }

      const walking = new BMap.WalkingRoute(this.mymap, {
        renderOptions: { autoViewport: true }, // 设置 panel 为 null
        onSearchComplete: (results) => {
          if (walking.getStatus() !== BMAP_STATUS_SUCCESS) {
            return;
          }
          const firstPath = results.getPlan(0).getRoute(0);
          this.paths.push(firstPath);
          if (this.points.length < 2) {
            this.paths.forEach((path, index) => {
              this.drawPath(this.mymap, path);
            });
          } else {
            const startPoint = new BMap.Point(this.points[0].lng, this.points[0].lat);
            const endPoint = new BMap.Point(this.points[1].lng, this.points[1].lat);
            this.points.shift();
            walking.search(startPoint, endPoint);
          }
        },
      });
      if (this.points.length >= 2) {
        const startPoint = new BMap.Point(this.points[0].lng, this.points[0].lat);
        const endPoint = new BMap.Point(this.points[1].lng, this.points[1].lat);
        this.points.shift();
        walking.search(startPoint, endPoint);
      }
    },
    drawPath(map, path) {
      const BMap = window.BMap;
      const points = path.getPath();
      const polyline = new BMap.Polyline(points, { strokeColor: "blue", strokeWeight: 5, strokeOpacity: 0.5 });
      map.addOverlay(polyline);
    },
    addMarkerWithLabel(map, point, text) {
      const BMap = window.BMap;
      const marker = new BMap.Marker(point);
      const label = new BMap.Label(text, { offset: new BMap.Size(-10, -20) });
      label.setStyle({
        color: "white",
        fontSize: "16px",
        border: "0",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "5px",
        padding: "3px",
      });
      marker.setLabel(label);
      map.addOverlay(marker);
    },
    async handleButtonClick() {
      const loadingInstance = ElLoading.service({ fullscreen: true });

      const url = "https://api.openai-sb.com/v1/chat/completions";
      const requestText = this.dialogContent;
      const sceneData = JSON.stringify(this.sceneData);
      const promt = `你是一个导游，我将以json形式给出几条线路时长(duration)，特色(feature)。请根据用户需求给出旅游路线的建议。需要重点考虑线路时长。旅行建议只给最合适的那个。选择理由模仿专业导游的语气，请注意不要出现路线数字id。用户需求为："${requestText}"。旅游路线数据: ${sceneData}。使用json格式回复，回复内容包含建议路线id(key=id)和建议理由(key=reason)`;

      const data = {
        messages: [{"role": "user", "content": promt}],
        model: 'gpt-3.5-turbo',
      };
      const apiKey = "sb-4221e5281f89c698b05e59639fb249e0c3225a1db9a1f3db";
      const result = await this.postData(url, data, apiKey);
      const jsonObj = JSON.parse(result.choices[0].message.content)

      const idx = jsonObj.id;

      this.points = []
      var targetPath = []
      this.sceneData.forEach((obj, index) => {
        if (obj.id == idx) {
          targetPath = obj.path;
          return;
        }
      });

      console.log("API 返回值：", targetPath);
      targetPath.forEach((obj, index) => {
        const point = {lng: obj.latitude, lat: obj.longitude, name: obj.name };
        this.points.push(point);
      })
      this.drawMap();
      this.textBoxContent = jsonObj.reason;
      this.showTextBox = true;
      loadingInstance.close();
    },
    async postData(url = "", data = {}, apiKey) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`, // 设置 Authorization 头
        },
        body: JSON.stringify(data),
      });
      return response.json();
    },
  },
};
</script>
  
<style scoped>
#map {
  width: 100%;
  height: 100%;
  
}
.map-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.map-card {
  width: 100%;
  margin-top: 20px;
}
.dialog {
  top: 20px;
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  justify-content: center;
}

.dialog-container {
  display: flex;
  align-items: center;
  width: 100%; /* 设置宽度 */
}

.dialog-button {
  margin-left: 2px; /* 设置左边距 */
}
.text-box {
  background-color: #f5f5f5; /* 设置背景颜色 */
  border: 1px solid #ccc; /* 设置边框 */
  padding: 10px; /* 添加内边距 */
  text-align: left; /* 文字居左 */
}

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}
</style>